import React, { Component } from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import { Typography, Grid } from "@material-ui/core";
import Search from 'components/search';
import AssetHelper, { ImageVariants } from 'shared/AssetHelper';
import sliceStringWithDots from 'shared/sliceStringWithDots';
import {translate} from "react-i18next";
import ReactPaginate from 'react-paginate';
import styles from './index.scss';
import Spinner from 'components/spinner';
import Loader from 'components/loader.jsx';

const presentationsSyles = theme => ({
  container: {
    position: "fixed",
    cursor: "default",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1200px",
    zIndex: 999,
    background: "rgba(0,0,0,0.5)",
    borderRadius: "6px",
    pointerEvents: "all",
    overflow: 'hidden',
    height: "80vh",
    bottom: 100,
    display: 'flex',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      top: 86,
      left: 0,
      transform: 'none',
      maxWidth: 'none',
      overflow: 'auto',
      paddingBottom: 50
    },
  },
  close: {
    color: "white",
    position: "absolute",
    display: "inline-block",
    overflow: "hidden",
    cursor: "pointer",
    top: "0",
    right: "0",
    width: "32px",
    height: "32px",
    margin: "20px",
    "&:hover": {
      transform: "rotate(90deg)",
      transition: "all ease-in 0.2s",
    },
    [theme.breakpoints.down('sm')]: {
      // top: 85
    },
  },
  title: {
    color: '#fff',
    fontSize: '28px',
    margin: '20px',
    fontFamily: '"brandon", sans-serif',
    fontWeight: "400",
    textAlign: 'center',
  },
  presentation: {
    backgroundColor: 'white',
    padding: 16,
    margin: 20,
    width: '29%',
    minHeight: 265,
    maxHeight: 265,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '40%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '99%'
    },
  },
  presentationImage: {
    width: '100%',
    height: 180,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  description: {
    marginTop: 14,
    fontSize: 15,
    fontWeight: 500
  },
  searchRow: {
    paddingRight: 25,
    paddingLeft: 25,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
  },
  search: {
    width: 352,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  emptyState: {
    height: '100%'
  },
  emptyInfo: {
    fontSize: 26,
    color: 'white'
  },
  list: {
    flex: '2 1 auto',
    overflow: 'auto'
  },
});

class UserPresentations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true
    };
  }

  render() {
    const {
      t,
      classes,
      presentations,
      onClose,
      onTextChange,
      searchString,
      currentId,
      onSelectPresentation,
      handlePageChange,
      pageNum,
      metadata,
      isLoading
    } = this.props;
    const filtered = presentations.filter(presentation => presentation.id !== currentId);
    return (
      <Grid container className={classes.container} direction="column" alignItems='center' wrap='nowrap'>
        <Close className={classes.close} color="white" onClick={onClose}/>
        {((filtered && filtered.length) || (filtered && !filtered.length && searchString && searchString.length && !this.firstLoad)) && (
          <React.Fragment>
            <Grid container direction="column" wrap="nowrap">
              <Typography item className={classes.title}>{t('viewingHost.myVirtualTours')}</Typography>
            </Grid>
            <Grid container className={classes.searchRow}>
              <Search
                className={classes.search}
                onTextChange={e => {
                  if (this.state.firstLoad) {
                    this.setState({
                      firstLoad: false
                    });
                  }
                  onTextChange(e);
                }}
                text={searchString}
              />
            </Grid>
          </React.Fragment>
        )}
        <React.Fragment>
          {isLoading ? (
            <Grid container alignItems='center' justify="center" style={{height: '100%'}}>
              <Spinner style={{width: 100, height: 100}}></Spinner>
            </Grid>
          ) : (
            <React.Fragment>
              {filtered && filtered.length ? (
                <Grid item container alignItems='stretch' justify="center" className={classes.list}>
                  {filtered.map((presentation) => {
                    const hash = presentation.thumbnail && presentation.thumbnail.hash;
                    const imgUrl = hash ? AssetHelper.imagePath(hash, ImageVariants.WIDE) : '';
                    return (
                      <Grid item className={classes.presentation} onClick={() => onSelectPresentation(presentation.id)}>
                        <div className={classes.presentationImage} style={{backgroundImage: `url("${imgUrl}")`}}/>
                        <Typography className={classes.description}>{sliceStringWithDots(presentation.name, 60)}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : null}
            </React.Fragment>
          )}
        </React.Fragment>
        <ReactPaginate
          forcePage={(pageNum)}
          containerClassName="pagination"
          pageCount={metadata && metadata.pagesCount}
          onPageChange={(e) => {
            handlePageChange(e);
          }}
        />
        {(filtered && !filtered.length && !searchString && this.state.firstLoad) && !isLoading && (
          <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyState}>
            <Typography className={classes.emptyInfo}>{t('viewingHost.empty')}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

UserPresentations.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  presentations: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  currentId: PropTypes.number.isRequired,
  onSelectPresentation: PropTypes.func.isRequired,
};

export default compose(
  withStyles(presentationsSyles),
  translate('translations')
)(UserPresentations);
