import React, { Component } from 'react';
import { Api } from 'api';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from 'components/modal/index.jsx';
import axios from 'axios/index';

const styles = theme => ({
  linksRow: {
    flexWrap: "wrap",
  },
  linksCta: {
    width: "auto !important",
    flexBasis: "auto !important",
  },
  info: {
    color: "#dba101",
    marginBottom: 16,
  },
  success: {
    color: "#0e7e3b",
  },
  buttonEnable: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  buttonEnableText: {
    color: 'black !important',
  },
  indexZ: {
    zIndex: '100 !important',
  },
  stayInBrowser: {
    textTransform: 'uppercase',
    color: 'grey',
    fontSize: 13,
    marginBottom: 16,
    cursor: 'pointer'
  }
});

class ConnectionStatusModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isEmailCorrect: false,
      vr: false
    };

    this.copyLink = this.copyLink.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.sendEmailInvite = this.sendEmailInvite.bind(this);
    this.isVRAviable = this.isVRAviable.bind(this);
  }
  componentWillReceiveProps() {
    this.isVRAviable();
  }
  isVRAviable() {
    this.props.player && this.props.player.isVRCompatible().then(() => {
      if (this.props.isMobile) {
        this.setState({ vr: false });
      } else {
        this.setState({ vr: true });
      }
    }, () => {
      this.setState({ vr: false });
    });
  }
  copyLink() {
    if (!this.linkDiv) return;

    window.getSelection().selectAllChildren( this.linkDiv );
    document.execCommand("copy");
  }
  emailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }
  sendEmailInvite() {
    const { email } = this.state,
      { id, presentationId, openSnackbar, hash, t } = this.props;

    if (!id || !email || !presentationId) return;

    axios.post(
      Api.viewings.invite.replace(':hash', hash),
      {
        email: email
      }).then(
      () => {
        this.setState({
          email: '',
        });
      },
      () => {
        this.setState({
          email: '',
        });
      }
    );
    openSnackbar(t('presentation.shareCoviewingNotification'));
  }
  validateEmail(inputText) {
    /* eslint-disable-next-line */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ isEmailCorrect: emailRegex.test(String(inputText).toLowerCase()) });
  }
  render() {
    const { t, open, onClose, hash, classes } = this.props;
    const { email, isEmailCorrect } = this.state;
    const link = hash && `${location.protocol}//${location.host}/v/${hash}`;

    const status = this.props.status;
    const modalFixed = status === 'hostConnected' || status === 'hostDisconnected';
    const title = status === 'hostDisconnected' ? t('presentation.connectionStatusModal.connecting') : t('presentation.connectionStatusModal.title');

    return (
      <Modal open={open} overlay onClose={onClose} className={`modal--title modal--startCoViewing ${classes.indexZ}`} fixed={modalFixed} hideCloseButton={status === 'peerUpgraded' || status === 'hostConnected'}>
        <div className="modal-title">{title}</div>
        <div className="modal-content">
          {status === 'hostDisconnected' ?
            (
              <div>
                <input
                  type="button"
                  value={t('presentation.connectionStatusModal.refresh')}
                  className="cta-button"
                  onClick={() => window.location.reload()}
                  style={{ marginTop: 25 }}
                />
                <div className="error" style={{ marginBottom: 25 }}>
                  {t('presentation.connectionStatusModal.connectingInfo1')}
                  {t('presentation.connectionStatusModal.connectingInfo2')}
                </div>
              </div>
            ) : (
              <div>
                <div className="hash-title">{t('presentation.connectionStatusModal.hash')}:</div>
                <div className="hash-value">{hash}</div>
                {this.props.clientName && (
                  <React.Fragment>
                    {(status === 'clientConnected' || status === 'peerUpgraded' || status === 'audioConnected') && (
                      <div className={status === 'clientConnected' ? 'error' : 'success'}>
                        {`${t('presentation.connectionStatusModal.conectedInfo1')} ${this.props.clientName} ${t('presentation.connectionStatusModal.conectedInfo2')}`}
                      </div>
                    )}
                    {status === 'clientConnected' && <div className="error">{t('presentation.connectionStatusModal.audioUnaviable')}</div>}
                  </React.Fragment>
                )}
                {status === 'audioConnected' && <div className="success">{t('presentation.connectionStatusModal.audioOn')}</div>}
                {status === 'peerUpgraded' && (
                  <React.Fragment>
                    <div className="success">{t('presentation.connectionStatusModal.audioAviable')}</div>
                    <input
                      type="button"
                      value={this.state.vr ? t('presentation.connectionStatusModal.startVRMode') : t('presentation.connectionStatusModal.start')}
                      className="cta-button"
                      onClick={() => {
                        this.props.connectAudio();
                        onClose();
                        if (this.state.vr) {
                          this.props.startVRMode();
                        }
                      }}
                      style={{ marginTop: 25 }}
                    />
                    {this.state.vr ? <div className={classes.stayInBrowser} onClick={() => {
                      this.props.connectAudio();
                      onClose();
                    }}>{t('presentation.connectionStatusModal.stayInBrowser')}</div> : null}
                  </React.Fragment>
                )}
                {(status === 'hostConnected' || !this.props.clientName) && (
                  <div className={classes.info}>{t('presentation.connectionStatusModal.connectedAwaiting')}</div>
                )}
              </div>
            )
          }
        </div>
        {status !== 'hostDisconnected' && (
          <div className="modal-secondary-content">
            <div className={classNames('links-row', classes.linksRow)}>
              <div className="links-detail">
                <div className="links-label">{t('presentation.viewingModal.link')}:</div>
                <div className="link" ref={linkDiv => this.linkDiv = linkDiv }>{link}</div>
              </div>
              <div className={classNames('links-cta', classes.linksCta)}>
                <Button onClick={this.copyLink}>
                  {t('presentation.viewingModal.copyLink')}
                </Button>
              </div>
            </div>
            <div className={classNames('links-row', classes.linksRow)}>
              <div className="links-detail">
                <div className="links-label">{t('presentation.viewingModal.email')}:</div>
                <TextField
                  className="links-textfield"
                  name="email"
                  placeholder={t('presentation.viewingModal.emailPlaceholder')}
                  value={email}
                  onChange={(e) => {
                    this.emailChange(e);
                    this.validateEmail(e.target.value);
                  }}
                />
              </div>
              <div className={classNames('links-cta', classes.linksCta)}>
                <Button className={classNames('send-invite-btn', isEmailCorrect && classes.buttonEnable)} disabled={!isEmailCorrect} onClick={this.sendEmailInvite}>
                  <span className={classNames(isEmailCorrect && classes.buttonEnableText)}>{t('presentation.viewingModal.sendInvite')}</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

ConnectionStatusModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hash: PropTypes.func.isRequired,
  id: PropTypes.func.isRequired,
  presentationId: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  connectAudio: PropTypes.func.isRequired,
  clientName: PropTypes.string.isRequired,
};

ConnectionStatusModal.defaultProps = {
};

export default compose(
  translate('translations'),
  withStyles(styles)
)(ConnectionStatusModal);
