import React, { Component } from "react";
import { translate } from "react-i18next";
import { PlayerContext } from 'components/player/index.jsx';
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import { WithDependency } from "../../shared/DependencyContext";
import axios from "axios";
import { actions as uiActions } from 'shared/redux/ui';
import './index.scss';
import { selectors } from "../../shared/redux/auth";
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import IconGuide from 'home/icons/guide.svg';
import IconFollow from 'home/icons/follow.svg';
import IconPan from '@material-ui/icons/PanTool';
import IconBrush from '@material-ui/icons/Brush';
import IconDelete from '@material-ui/icons/Delete';
import IconCallEnd from '@material-ui/icons/CallEnd';
import IconCall from '@material-ui/icons/Call';
import IconMicOff from '@material-ui/icons/MicOff';
import IconMic from '@material-ui/icons/Mic';
import IconInfo from 'static/home/presentation/info-icon.svg';
import IconVolumeUp from '@material-ui/icons/VolumeUp';
import IconVolumeOff from '@material-ui/icons/VolumeOff';
import { NavBarContext, NavBarStyles } from 'components/navBar';
import Config from 'config';
import PresentationDashboard from 'components/presentation-dashboard';
import ScenesNav from 'components/scenes-nav';
import Classnames from 'classnames';
import ConnectionStatusModal from './components/ConnectionStatusModal';
import UserPresentations from './components/UserPresentations';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '../../components/snackbar/snackbar';
import closeJivoChat from '../../shared/closeJivoChat';
import _debounce from 'lodash/debounce';

import {
  actions as presentationActions,
  selectors as presentationSelectors,
} from 'shared/redux/presentation';
import {
  actions as presentationsActions,
  selectors as presentationsSelectors
} from 'shared/redux/presentations';

class ViewingHost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hostConnected: false,
      clientConnected: false,
      audioConnected: false,
      fullscreenActive: false,
      qrCodeModalOpened: false,
      presentingMode: true,
      toolSelected: evr.Viewing.Tools.pan,
      volume: .5,
      localMute: false,
      remoteMute: false,
      clientName: null,
      sync: false,
      presentationDashboardOpen: false,
      presentationDashboardVisibe: false,
      viewingModalOpen: false,
      changeSceneWithPinEnabled: true,
      changeSceneWithArrowsEnabled: true,
      changeSceneWithScenesNavEnabled: true,
      isSnackbarOpen: false,
      coViewingModalTitle: null,
      connectionStatus: 'hostDisconnected',
      openConnectionStatusModal: true,
      blockCall: false,
      vrAviable: false,
      nextPresentationId: 3712,
      userPresentationsOpened: false,
      presentationsSearchString: '',
      snackbarMessage: '',
      isMobile: false,
      pageNum: 0,
      pageSize: 10,
      pagesCount: 0,
    };
    setInterval(() => {
      if (window.hostConnectionStatus === 'clientConnected') {
        this.setState({
          presentationDashboardVisibe: true,
        });
      } else if (window.hostConnectionStatus === 'clientDisconnected') {
        this.setState({
          presentationDashboardVisibe: false,
        });
      }
    }, 1000);

    if (location.search.indexOf('app') > -1) {
      document.body.classList.add('ui-hidden');
    }

    document.querySelector('.evr-player').classList.add('evr-player--viewing-host');

    this.props.updateUi({navBarVisible: false});
    this.props.updateUi({footerVisible: false});

    this.player = null; // player instance
    this.viewingHost = new evr.Viewing.Host({
      player: this.props.player.instance.instance,
      presentingMode: this.state.presentingMode,
      toolSelected: this.state.toolSelected,
      debug: true,
    });
    this.viewingHost.setConfig({
      socketHost: Config.socketServer.host,
    });
    this.viewingHost.on('hostConnected', () => {
      this.setState({
        hostConnected: true,
        connectionStatus: 'hostConnected',
        openConnectionStatusModal: true,
      });
    });
    this.viewingHost.on('clientConnected', () => {
      window.hostConnectionStatus = 'clientConnected';
      this.setState({
        clientConnected: true,
        connectionStatus: 'clientConnected',
        openConnectionStatusModal: true,
      });
    });
    this.viewingHost.on('peerUpgraded', () => {
      this.setState({
        peerConnected: true,
        connectionStatus: 'peerUpgraded',
        openConnectionStatusModal: true,
      });
    });
    this.viewingHost.on('hostDisconnected', () => {
      this.setState({
        hostConnected: false,
        connectionStatus: 'hostDisconnected',
        openConnectionStatusModal: true,
      });
    });
    this.viewingHost.on('clientDisconnected', () => {
      this.props.notifications.add(this.state.clientName + this.props.t('viewing.messages.clientDisconnected'));
      window.hostConnectionStatus = 'clientDisconnected';
      this.setState({
        clientConnected: false,
        // switch to guide mode when client disconnected
        presentingMode: true,
        connectionStatus: 'hostConnected',
        openConnectionStatusModal: true,
      });
    });
    this.viewingHost.on('peerDowngraded', () => {
      this.setState({
        peerConnected: false,
      });
    });
    this.viewingHost.on('audioConnected', () => {
      this.props.notifications.add(this.props.t('viewing.messages.voiceCallStarted'));
      console.log('Direct audio connected');
      this.viewingHost.applyVolume(this.state.volume);
      this.setState({
        audioConnected: true,
        connectionStatus: 'audioConnected',
        openConnectionStatusModal: false,
      });
    });
    this.viewingHost.on('audioDisconnected', () => {
      this.props.notifications.add(this.props.t('viewing.messages.voiceCallCompleted'));
      console.log('Direct audio disconnected');
      this.setState({
        audioConnected: false,
        remoteMute: false,
        localMute: false,
        connectionStatus: 'peerUpgraded',
      });
    });
    this.viewingHost.on('micAccessFailed', error => {
      this.props.notifications.add(this.props.t('viewing.messages.micAccessFailed'));
      this.setState({ blockCall: true });
    });
    this.viewingHost.on('peerMicMuted', muted => {
      console.log('Clint mic mute state:', muted);
    });
    this.viewingHost.on('clientName', name => {
      this.setState({ clientName: name });
    });
    this.viewingHost.on('modeChanged', mode => {
      if (mode === 'guide') {
        this.setState({
          changeSceneWithPinEnabled: true,
          changeSceneWithArrowsEnabled: true,
          changeSceneWithScenesNavEnabled: true,
        })
      } else (
        this.setState({
          changeSceneWithPinEnabled: false,
          changeSceneWithArrowsEnabled: false,
          changeSceneWithScenesNavEnabled: false,
        })
      )
    })

    this.openMenu = this.openMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.toggleVR = this.toggleVR.bind(this);
    this.toggleQRModal = this.toggleQRModal.bind(this);
    this.onToggleMode = this.onToggleMode.bind(this);
    this.handleToolChange = this.handleToolChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleVolumeSlider = this.handleVolumeSlider.bind(this);
    this.handleCallClick = this.handleCallClick.bind(this);
    this.handleMicClick = this.handleMicClick.bind(this);
    this.handleVolumeClick = this.handleVolumeClick.bind(this);
    this.toggleSync = this.toggleSync.bind(this);
    this.onTogglePresentationDashboard = this.onTogglePresentationDashboard.bind(this);
    this.closeViewingModal = this.closeViewingModal.bind(this);
    this.openViewingModal = this.openViewingModal.bind(this);
    this._setMenuContextLink = this._setMenuContextLink.bind(this);
    this.handleSnackbarOpen = this.handleSnackbarOpen.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.detectMobile = this.detectMobile.bind(this);
    this.isVRAviable = this.isVRAviable.bind(this);
    this.handleOpenUserPresentations = this.handleOpenUserPresentations.bind(this);
    this.handleCloseUserPresentations = this.handleCloseUserPresentations.bind(this);
    this.onSearchStringChange = this.onSearchStringChange.bind(this);
    this.fetchPresentations = this.fetchPresentations.bind(this);
    this.handleChangePresentation = this.handleChangePresentation.bind(this);
    this.onChangeScene = this.onChangeScene.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.fetchPresentationsDebounced = _debounce(this.fetchPresentations, 200, { leading: false });

    window.addEventListener("fullscreenchange", this.onFullscreenChange);
    window.addEventListener("mozfullscreenchange", this.onFullscreenChange);
    window.addEventListener("webkitfullscreenchange", this.onFullscreenChange);

    if (this.props.match.params.hash) {
      this.props.fetchViewing({hash: this.props.match.params.hash});
    }
  }
  _initViewing(hash, presentation) {
    if (presentation) {
      this.player.disableRotateScene();
      this.player.load(presentation);
    }

    this.viewingHost.connect({hash});
  }
  _setMenuContextLink() {
    this.props.navBar.setContextLinks([
      <MenuItem className="popover__menuItem" onClick={this.openViewingModal} ><a>{this.props.t('navBar.resendInvite')}</a></MenuItem>,
      <MenuItem className="popover__menuItem" onClick={this.handleOpenUserPresentations} ><a>{this.props.t('viewingHost.showOtherPresentation')}</a></MenuItem>
    ]);
  }
  componentDidMount() {
    closeJivoChat();

    const { navBar } = this.props;

    navBar.setStyle(NavBarStyles.minimalPlayer);
    navBar.hidePageLinks();

    this.setState({ isMobile: this.detectMobile() });


    if (this.player) {
      this.player.instance.getInstance().on('changeScene', this.onChangeScene);
      if (this.props.viewing && this.props.viewing.hash && this.props.presentation) {
        this._initViewing(this.props.viewing.hash, this.props.presentation);
      }
      this.isVRAviable();
    }
    this.fetchPresentations();

    setTimeout(function(){
      if (this.state.vrAviable) {
        window.isVr = true;
      }
    }.bind(this), 2000);
  }
  componentWillReceiveProps(nextProps) {
    let nextViewingHash = nextProps.viewing && nextProps.viewing.hash;

    if (!this.player && nextProps.player.instance) {
      this.player = nextProps.player.instance;
    }

    if (nextViewingHash === undefined) {
      this.player.unload();
    } else if (nextViewingHash !== this.props.viewing.hash &&
      nextProps.presentation) {
      this._initViewing(nextViewingHash, nextProps.presentation);
      this._setMenuContextLink();
    }
  }

  componentWillUnmount() {
    document.querySelector('.evr-player').classList.remove('evr-player--viewing-host');
    this.props.updateUi({footerVisible: true});
    window.removeEventListener("fullscreenchange", this.onFullscreenChange);
    window.removeEventListener("mozfullscreenchange", this.onFullscreenChange);
    window.removeEventListener("webkitfullscreenchange", this.onFullscreenChange);
    this.props.clearPresentation();
    this.player.unload();
    this.viewingHost.disconnect();
    this.props.navBar.setContextLinks();
  }
  onChangeScene(e) {
    console.log('onChangeScene',e);
  }
  handleOpenUserPresentations() {
    this.setState({ userPresentationsOpened: true, openConnectionStatusModal: false });
  }
  handleCloseUserPresentations() {
    this.setState({ userPresentationsOpened: false });
  }
  openMenu() {
    if (this.player) {
      this.player.openMenu();
    }
  }
  toggleFullscreen() {
    function requestFullscreen() {
      let elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }

    function exitFullscreen() {
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }

    if (this.state.fullscreenActive) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }
  }
  onFullscreenChange() {
    this.setState({
      fullscreenActive: !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement)
    });
  }
  toggleVR() {
    if (this.player) {
      this.player.isVRCompatible().then(() => {
        this.player.toggleVRMode();
      }, this.toggleQRModal);
    } else {
      this.toggleQRModal();
    }
  }
  toggleQRModal() {
    this.setState({
      qrCodeModalOpened: !this.state.qrCodeModalOpened
    });
  }
  onToggleMode() {
    if (this.viewingHost) {
      const { presentingMode, sync } = this.state;

      this.viewingHost.setMode({presenting: !presentingMode, mirror: sync});
      this.setState({
        presentingMode: !this.state.presentingMode,
      });
    }
  }
  handleToolChange(tool) {
    if (this.viewingHost && evr.Viewing.Tools[tool]) {
      this.setState({
        toolSelected: tool
      });
      this.viewingHost.setTool(tool);
    }
  }
  handleClear() {
    if (this.viewingHost) {
      this.viewingHost.clearDrawing();
    }
  }
  handleVolumeSlider(e, value) {
    if (this.viewingHost) {
      this.viewingHost.applyVolume(value);
    }
  }
  handleCallClick() {
    if (this.state.audioConnected) {
      this.viewingHost.disconnectAudio();
    } else {
      this.viewingHost.connectAudio();
    }
  }
  handleMicClick() {
    if (this.viewingHost) {
      this.viewingHost.toggleMic();
      this.setState({
        localMute: !this.state.localMute,
      });
    }
  }
  handleVolumeClick() {
    if(this.viewingHost) {
      this.viewingHost.toggleSpeaker();
      this.setState({
        remoteMute: !this.state.remoteMute,
      });
    }
  }
  onTogglePresentationDashboard(open) {
    this.setState({
      presentationDashboardOpen: open,
    });
  }
  closeViewingModal() {
    this.setState({
      viewingModalOpen: false,
      coViewingModalTitle: null,
    });
  }
  openViewingModal() {
    this.props.navBar.handleCloseHeader();
    this.setState({
      viewingModalOpen: true,
    });
  }
  toggleSync() {
    if (this.viewingHost) {
      const { presentingMode, sync } = this.state;

      this.viewingHost.setMode({presenting: presentingMode, mirror: !sync});
      this.setState({
        sync: !sync,
      })
    }
  }
  handleSnackbarOpen(msg) {
    this.setState(() => ({
      isSnackbarOpen: true,
      snackbarMessage: msg
    }))
  }
  handleSnackbarClose() {
    this.setState(() => ({
      isSnackbarOpen: false,
      snackbarMessage: ''
    }))
  }
  detectMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  isVRAviable() {
    this.player && this.player.isVRCompatible().then(() => {
      if (this.detectMobile()) {
        this.setState({ vrAviable: false });
      } else {
        this.setState({ vrAviable: true });
      }
    }, () => this.setState({ vrAviable: false }));
  }
  handleChangePresentation(id) {
    const { t, viewing, updateViewing } = this.props;
    axios.post(`/api/v1/viewings/${this.props.viewing.hash}/presentation`, { presentationId: id }).then(// TODO redux
      response => {
        this.setState({
          userPresentationsOpened: false,
          viewingHash: response.data.hash,
          viewingId: response.data.id,
        });
        this.props.fetchViewing({hash: response.data.hash});
        this.viewingHost.reload();
      },
      error => {
        this.handleSnackbarOpen(t('viewingHost.cannotLoad'));
        console.error('handleChangePresentation', error);
      }
    );
  }
  fetchPresentations(page) {
    const { presentationsSearchString, pageNum, pageSize } = this.state;
    const action = this.props.fetchPresentationsProfile;
    const payload = { sort: 'publicationDateDesc', pageNum: page ? page.selected : pageNum, pageSize };
    if (presentationsSearchString) {
      payload.text = presentationsSearchString;
    }
    action(payload);
  }
  onSearchStringChange(newText) {
    const { presentationsSearchString } = this.state;
    if (newText !== presentationsSearchString) {
      this.setState({
        presentationsSearchString: newText,
      });
      this.fetchPresentationsDebounced();
    }
  }
  handlePageChange(page) {
    this.setState({
      pageNum: page.selected
    });
    this.fetchPresentations(page);
  }
  render() {
    const { t, viewing, presentation, presentations, metadata, isLoading } = this.props,
      {
        clientConnected,
        hostConnected,
        peerConnected,
        audioConnected,
        presentingMode,
        sync,
        presentationDashboardOpen,
        changeSceneWithPinEnabled,
        changeSceneWithArrowsEnabled,
        changeSceneWithScenesNavEnabled,
        isSnackbarOpen,
        connectionStatus,
        presentationDashboardVisibe,
        blockCall,
        vrAviable,
        snackbarMessage,
        userPresentationsOpened,
        presentationsSearchString,
        pageNum,
        pagesCount
      } = this.state;
    vrAviable ? window.isVr = 'true' : null;
    let connected = hostConnected && clientConnected,
      viewingHash = viewing && viewing.hash,
      viewingId = viewing && viewing.id,
      viewingLoading = !viewing || !viewing.id || !viewing.hash,
      presentationId = presentation && presentation.id,
      iconParticipants,
      labelParticipants,
      viewingControlsClass = Classnames({
        'viewing-controls': true,
        'viewing-controls--open': presentationDashboardOpen,
      });

    if (connected) {
      iconParticipants = <div className={"status connected"}></div>;
      labelParticipants = this.state.clientName || viewing.email || t('viewingHost.anonymousClient');
    } else {
      iconParticipants = <div className={"status"}></div>;
      labelParticipants = t('viewingHost.waiting');
    }

    return (
      <div className={"viewing-host"}>
        {presentationDashboardVisibe && <PresentationDashboard
          minimal={true}
          presentation={presentation}
          vrButtonVisible={false}
          fsButtonVisible={false}
          autoplayButtonVisible={false}
          menuButtonVisible
          controlsWhite
          onToggle={this.onTogglePresentationDashboard}
          changeSceneWithPinEnabled={changeSceneWithPinEnabled}
          changeSceneWithArrowsEnabled={changeSceneWithArrowsEnabled}
        >
          <ScenesNav presentation={presentation} changeSceneWithScenesNavEnabled={changeSceneWithScenesNavEnabled} />
        </PresentationDashboard>}
        {connectionStatus !== 'hostDisconnected' && connectionStatus !== 'hostConnected' && (
          <div className={Classnames(viewingControlsClass, this.detectMobile && "mobile-height")}>
            <div className="viewing-controls__block">
              <IconButton
                className="info-icon"
                onClick={() => {
                  if (this.state.openConnectionStatusModal) {
                    this.setState({ openConnectionStatusModal: false })
                  } else {
                    this.setState({ openConnectionStatusModal: true })
                  }
                }}
              >
                <IconInfo className="tool-icon" width={40} height={40} />
              </IconButton>
              <div className="viewing-controls__participants">
                {iconParticipants}
                <span>{labelParticipants}</span>
              </div>
            </div>
            <div className="viewing-controls__block">
              <div className="mode-switch" onClick={this.onToggleMode}>
                {presentingMode ? <IconFollow /> : <IconGuide />}
                <p>{presentingMode ? t('viewingHost.guide') : t('viewingHost.follow')}</p>
              </div>
              <div className="viewing-controls__checkbox">
                <Checkbox disabled={presentingMode} checked={!presentingMode && sync} onChange={this.toggleSync} color="primary"/>
                <p>Sync</p>
              </div>
            </div>
            <div className="viewing-controls__block">
              <IconButton
                target="_blank"
                secondary={true}
                onClick={() => this.handleToolChange(evr.Viewing.Tools.pan)}
              >
                <IconPan className={"tool-icon" + (this.state.toolSelected === evr.Viewing.Tools.pan ? ' selected':'')} style={{marginLeft: '-2.4px'}} />
              </IconButton>
              <IconButton
                target="_blank"
                secondary={true}
                onClick={() => this.handleToolChange(evr.Viewing.Tools.draw)}
              >
                <IconBrush className={`tool-icon ${this.state.toolSelected === evr.Viewing.Tools.draw ? ' selected':''}`} />
              </IconButton>
              <IconButton
                target="_blank"
                secondary={true}
                onClick={this.handleClear}
              >
                <IconDelete className="tool-icon" />
              </IconButton>
            </div>
            <div className="viewing-controls__block">
              <IconButton
                target="_blank"
                secondary={true}
                onClick={this.handleCallClick}
                disabled={!peerConnected || blockCall}
              >
                {
                  audioConnected ?
                    <IconCallEnd className="tool-icon"/>:
                    <IconCall className={`tool-icon ${!peerConnected || blockCall ? 'disabled': ''}`}/>
                }
              </IconButton>
              <IconButton
                target="_blank"
                secondary={true}
                disabled={!audioConnected}
                onClick={this.handleMicClick}
              >
                {
                  this.state.localMute ?
                    <IconMicOff className="tool-icon"/> :
                    <IconMic className={`tool-icon ${!audioConnected ? 'disabled': ''}`}/>
                }
              </IconButton>
              <IconButton
                target="_blank"
                disabled={!audioConnected}
                secondary={true}
                onClick={this.handleVolumeClick}
              >
                {
                  this.state.remoteMute ?
                    <IconVolumeOff className="tool-icon"/> :
                    <IconVolumeUp className={`tool-icon ${!audioConnected ? 'disabled': ''}`}/>
                }
              </IconButton>
            </div>
          </div>
        )}
        {userPresentationsOpened && metadata && <UserPresentations
          presentations={presentations}
          currentId={presentation && presentation.id}
          searchString={presentationsSearchString}
          onTextChange={this.onSearchStringChange}
          onSelectPresentation={this.handleChangePresentation}
          onClose={this.handleCloseUserPresentations}
          pageNum={pageNum}
          pagesCount={pagesCount}
          handlePageChange={this.handlePageChange}
          metadata={metadata}
          isLoading={isLoading}
        />}
        <ConnectionStatusModal
          status={connectionStatus}
          open={this.state.openConnectionStatusModal}
          onClose={() => this.setState({ openConnectionStatusModal: false })}
          hash={viewingHash}
          id={viewingId}
          presentationId={presentationId}
          openSnackbar={this.handleSnackbarOpen}
          clientName={this.state.clientName}
          connectAudio={this.handleCallClick}
          player={this.player}
          startVRMode={() => this.player.toggleVRMode()}
          isMobile={this.state.isMobile}
        />
        <Snackbar open={isSnackbarOpen}
          onClose={this.handleSnackbarClose}
          autoHideDuration={4000}
          message={snackbarMessage}
          isSnackbarOpen={isSnackbarOpen}
          anchorOrigin={
            { vertical: 'top',
              horizontal: 'center' }}
          >
        </Snackbar>
      </div>
    );
  }
}

const {
  getCurrentUser
} = selectors;

const {
  getPresentation,
  getPresentationMeta,
  isFound,
  getViewing,
} = presentationSelectors;

const {
  getMetadata,
  getPresentations,
  isLoading,
} = presentationsSelectors

const {
  fetchPresentationsProfile,
} = presentationsActions;

const {
  fetchPresentation,
  clearPresentation,
  fetchViewing,
  updateViewing
} = presentationActions;

const {
  updateUi,
} = uiActions;

const mapStateToProps = state => ({
  presentations: getPresentations(state),
  currentUser: getCurrentUser(state),
  presentation: getPresentation(state),
  presentationMeta: getPresentationMeta(state),
  isLoading: isLoading(state),
  isFound: isFound(state),
  viewing: getViewing(state),
  metadata: getMetadata(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchPresentation,
    clearPresentation,
    updateUi,
    fetchViewing,
    fetchPresentationsProfile,
    updateViewing
  }, dispatch),
});

function ViewingHostNavBar(props) {
  return <NavBarContext.Consumer>
    {navBar =>
      navBar ? <ViewingHost
        {...props}
        navBar={navBar}
      /> : <div/>}
  </NavBarContext.Consumer>;
}

function PlayerViewingHost(props) {
  return (
    <PlayerContext.Consumer>
      {player => (
        <ViewingHostNavBar
          {...props}
          player={player.player}
        />
      )}
    </PlayerContext.Consumer>
  );
}

export default translate("translations")(connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate("translations")(WithDependency(PlayerViewingHost))));
